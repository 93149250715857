import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import { findOptions, getSegmentQueryParams } from '@/function'

import updateUrl from '@/mixins/updateUrl'
import regionFilter from '@/mixins/regionFilter'

import filters from './components/filters'
import container from '@/services/components/container/'
import catalogTable from './components/catalogTable/catalogTable'
import error from '@/services/errors/block'
import noProjects from '@/services/errors/noProjects'
import filtersResetButton from '@/services/components/filtersResetButton'
import multiaction from '@/services/components/multiaction'

import CONSTANTS from '@/const/'
import MODAL_TITLE from '@/modals/modalTitle'
import ROUTE_NAME from '@/router/routeName'

const PERIOD_COUNT = ['one', 'two']
const TRAFFIC_FILTER_TYPES = ['source', 'medium']

export default {
    metaInfo () {
        return {
            title: `${this.title.primary} / ${this.title.secondary} / ${this.activeProjectData.label} (${this.activeProjectData.id})`
        }
    },

    mixins: [
      updateUrl,
      regionFilter
    ],

    provide () {
        return {
            MAIN_FLAG: this.mainFlag,
            tableCellsConfigKey: this.tableCellsConfigKey
        }
    },

    components: {
        'v-filters': filters,
        'v-container': container,
        'v-catalog-table': catalogTable,
        'v-error': error,
        'v-no-projects': noProjects,
        'v-filters-reset-button': filtersResetButton,
        'v-multiaction': multiaction
    },

    props: {
        title: {
            type: Object,
            default: () => ({})
        },

        tableCellsConfig: {
            type: Array,
            default: () => ([])
        },

        metricFilters: {
            type: Array,
            default: () => ([])
        },

        page: {
            type: String,
            default: ''
        },

        apiUrl: {
            type: String,
            default: '/catalog'
        }
    },

    data () {
        return {
            preloader: {
                filters: true,
                table: true
            },

            MODAL_TITLE,

            preloaderWidth: 0,

            searchEngines: [
                {id: CONSTANTS.SEARCH_SYSTEM.GOOGLE, name: 'Google'},
                {id: CONSTANTS.SEARCH_SYSTEM.YANDEX, name: 'Яндекс'}
            ],

            itemsSelected: []
        }
    },

    computed: {
        ...mapState({
            catalog: state => state.catalog.catalog,
            activeProjectData: state => state.active_project_data,
            projects: state => state.options.projects,
            segmentsCatalogDF: state => state.segments.catalog.dataDF,
            segmentsCustomDF: state => state.segments.catalog.dataDFCustom,
            trafficConditions: state => state.trafficConditions,
            preloaderTable: state => state.catalog.preloaderTable,
            trafficSources: state => state.trafficSources
        }),

        ...mapGetters(['filtersDevices', 'lackOfProjects']),

        isCommerceAnalysisPage() {
            return this.$route.name === ROUTE_NAME.COMMERCE_ANALYSIS
        },

        /* ID активного проекта в URL */
        activeProjectId () {
            let id = this.$route.params.activeProject
            return id ? +id : undefined
        },

        /* Объединенное дерево кастомных и системных сегментов */
        allTreeSegments () {
          let allSegments = JSON.parse((JSON.stringify(this.segmentsCustomDF)))
          allSegments.items = allSegments.items.concat(this.segmentsCatalogDF.items)
          return allSegments
        },

        /* Фильтры в URL */
        urlFilters () {
            let queryParams = {...this.$route.query}
            let filters = {
                date: {
                    one: {
                        from: undefined,
                        to: undefined
                    },
                    two: {
                        from: undefined,
                        to: undefined
                    }
                }
            }

            /* Инициализация URL фильтров */
            const initFilters = (query, options, required = false, defaultOption, cycle, optionKey = 'id', number = false, string = false) => {
                try {
                    if (cycle) {
                        if (query) {
                            /* Проверка наличия опций в фильтрах */
                            if (query && Array.isArray(query)) {
                                return query.map(f => number ? +f : f).filter(f => f !== null && f !== undefined && ((!string && !isNaN(f)) || string))
                            } else if (typeof query === 'string' && number && !isNaN(+query)) {
                                return [+query]
                            } else if ((typeof query === 'number' && !isNaN(+query)) || typeof query === 'string' && !number) {
                                return [query]
                            } else {
                                return defaultOption || []
                            }
                        } else {
                            return defaultOption || []
                        }
                    } else {
                        if (typeof query === 'object' && options) {
                            for (let item of options) {
                                if (query.source && query.source === item.source && query.medium && query.medium === item.medium) {
                                    return item.id
                                }
                            }
                        } else {
                            if (typeof query === 'number' && (query || query === 0) && findOptions(query, options, optionKey)) {
                                return +query
                            } else if (query && findOptions(query, options)) {
                                return query
                            } else if (required) {
                                return defaultOption || options[0].id
                            } else {
                                return null
                            }
                        }
                    }
                } catch (_) {
                    return null
                }
            }

            filters.limit = queryParams.limit && +queryParams.limit <= 100 ? +queryParams.limit : 50

            filters.page = +queryParams.page ? +queryParams.page : 1

            filters.region_id = initFilters(queryParams.region_id, this.regions, false, undefined, true, 'google_region_id', true)

            filters.is_mobile = initFilters(+queryParams.is_mobile, this.devices)

            filters.catalog_ids = initFilters(queryParams.catalog_ids, this.allTreeSegments.items, false, [], true).filter(id => !isNaN(+id))

            this.metricFiltersQuery.forEach(filter => {
                filters[filter] = queryParams[filter]
            })

            filters.order_by = queryParams.order_by

            filters.order_direction = queryParams.order_direction

            filters.search_engine = initFilters(queryParams.search_engine, this.searchEngines)

            filters.traffic_source = initFilters(queryParams.traffic_source, this.trafficSources, true, 'ga4')

            for (let type of TRAFFIC_FILTER_TYPES) {
                filters[type] = initFilters(queryParams[type], undefined, false, [], true, undefined, false, true)
                filters[`traffic_conditions[${type}]`] = initFilters(queryParams[`traffic_conditions[${type}]`], this.trafficConditions)
            }

            for (let count of PERIOD_COUNT) {
                if (queryParams[`period_${count}_date_from`] && queryParams[`period_${count}_date_to`]) {
                    let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')
                    let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')

                    filters.date[count] = {
                        from: dateFrom || dateTo,
                        to: dateTo || dateFrom
                    }
                } else if (count === 'one') {
                    if (queryParams[`period_${count}_date_to`]) {
                        let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')

                        filters.date[count] = {
                            from: +this.$moment.utc(dateTo, 'x').subtract(7, 'days').format('x'),
                            to: dateTo
                        }
                    } else if (queryParams[`period_${count}_date_from`]) {
                        let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')
                        let dateTo = +this.$moment.utc(dateFrom, 'x').add(7, 'days').format('x')
                        filters.date[count].from = dateFrom

                        if (dateTo > +this.$moment.utc().endOf('day').format('x')) {
                            filters.date[count].to = +this.$moment.utc().startOf('day').format('x')
                        } else {
                            filters.date[count].to = dateTo
                        }
                    } else {
                        let dateDefault = +this.$moment.utc().subtract(1, 'days').startOf('day').format('x')
                        filters.date[count] = {
                            from: +this.$moment.utc(dateDefault, 'x').subtract(7, 'days').format('x'),
                            to: dateDefault
                        }
                    }
                }
            }

            return filters
        },

        dateFilters () {
            let dateFilters = {
                one: {
                    from: undefined,
                    to: undefined
                },
                two: {
                    from: undefined,
                    to: undefined
                }
            }

            const urlFiltersDate = {...this.urlFilters.date}

            for (let count of PERIOD_COUNT) {
                try {
                    if (urlFiltersDate[count] && urlFiltersDate[count].from) {
                        dateFilters[count].from = this.$moment(urlFiltersDate[count].from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
                    }

                    if (urlFiltersDate[count] && urlFiltersDate[count].to) {
                        dateFilters[count].to = this.$moment(urlFiltersDate[count].to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
                    }
                } catch (_) {
                }
            }

            return {
                ...dateFilters,
                comparison: !!dateFilters.two.from && !!dateFilters.two.to && (dateFilters.one.from !== dateFilters.two.from || dateFilters.one.to !== dateFilters.two.to)
            }
        },

        mainFlag () {
            let flag = this.$route.query.main
            return +flag === 1 ? flag : undefined
        },

        viewPage () {
            return this.activeProjectData && Object.keys(this.activeProjectData).length !== 0
        },

        metricFiltersQuery () {
            let filters = []

            this.metricFilters.forEach(filter => {
                filters.push(`${filter}_from`)
                filters.push(`${filter}_to`)
                filters.push(`${filter}_diff_from`)
                filters.push(`${filter}_diff_to`)
            })

            return filters
        },

        allItemsAreSelected () {
            return this.itemsSelected.length === this.catalog.items.length
        },

        someItemsAreSelected () {
            return !!this.itemsSelected.length
        },

        devices() {
            return this.filtersDevices
        },

        regionSelected() {
            return this.getRegionSelected(this.urlFilters.region_id)
        },

        tableCellsConfigKey() {
            return `${this.page}TableCellsConfig`
        },

        orderBy () {
          let orderBy = this.isCommerceAnalysisPage ? 'item_revenue' : 'sessions'
          return this.urlFilters.order_by ? this.urlFilters.order_by : this.dateFilters.comparison ? `${orderBy}_one` : orderBy
        }
    },

    watch: {
        async $route () {
            this.setCancelRequests(true)
            await this.init()
        },

        '$sizes.containerWidth'() {
            this.setPreloaderWidth()
        }
    },

    async created () {
        this.setCancelRequests(false)
        this.setTableCellsConfig()

        try {
            await this.getFilters({
                query: {
                    project_id: this.activeProjectId,
                    skip_regionality: 1
                }
            })
        } catch ({message}) {
            this.$notify({title: message, type: CONSTANTS.NOTIFY_STATUS.ERROR})
        }

        await this.getRegions()

        this.preloader.filters = false
        await this.init()
    },

    mounted () {
        this.$nextTick(() => {
            this.setPreloaderWidth()
        })
    },

    destroyed () {
        this.setCancelRequests(true)
    },

    methods: {
        ...mapActions({
            getCatalog: 'catalog/getCatalog',
            getFilters: 'getFilters'
        }),

        ...mapMutations({
            setPreloader: 'catalog/setPreloader',
            toggleTableRows: 'catalog/toggleTableRows',
            setCancelRequests: 'setCancelRequests',
            saveTableCellsConfig: 'tableSettings/saveTableCellsConfig'
        }),

        /* Загрузка данных */
        async requestData (catalogId, level, index, moreData, offset, summary, traffic_all) {
            if (this.activeProjectId) {
                let query = {
                  limit: catalogId ? 50 : this.urlFilters.limit,
                  offset: catalogId ? offset : (this.urlFilters.page - 1) * this.urlFilters.limit,
                  project_id: this.activeProjectId,
                  is_mobile: this.urlFilters.is_mobile === 0 ? '0' : this.urlFilters.is_mobile,
                  catalog_id: catalogId ? catalogId : '0',
                  skip_regionality: 1,
                  main: this.mainFlag,
                  v2: 1,
                  search_engine: this.urlFilters.search_engine,
                  summary,
                  traffic_all,
                  traffic_source: this.urlFilters.traffic_source
                }

                if (this.regionSelected) {
                    query.region_id = this.regionSelected.map(region => region.yandex_region_id).filter(id => id)
                    query.google_region_id = this.regionSelected.map(region => region.google_region_id)
                    query.need_geo = this.regionSelected && this.regionSelected.length ? 1 : undefined
                }

                for (let type of TRAFFIC_FILTER_TYPES) {
                    query[type] = this.urlFilters[type]
                    query[`traffic_conditions[${type}]`] = this.urlFilters[`traffic_conditions[${type}]`]
                }

                if (!traffic_all) {
                  const {segments, catalog_ids} = getSegmentQueryParams(this.segmentsCustomDF, this.segmentsCatalogDF, this.urlFilters)

                  query.catalog_ids = catalog_ids
                  query.segments = segments

                  this.metricFiltersQuery.forEach(filter => {
                      if (this.urlFilters[filter]) {
                          query[filter] = this.urlFilters[filter]
                      }
                  })
                }

                if (!summary) {
                    query.order_direction = this.urlFilters.order_direction ? this.urlFilters.order_direction : 'DESC'
                    query.order_by = this.orderBy
                }

                if (this.dateFilters.comparison) {
                    query.period_one_date_from = this.dateFilters.one.from
                    query.period_one_date_to = this.dateFilters.one.to
                    query.period_two_date_from = this.dateFilters.two.from
                    query.period_two_date_to = this.dateFilters.two.to
                } else {
                    query.date_from = this.dateFilters.one.from
                    query.date_to = this.dateFilters.one.to
                }

                await this.getCatalog({
                    query,
                    level: level ? moreData ? +level : +level + 1 : '0',
                    index,
                    moreData,
                    url: this.apiUrl
                })
            }
        },

        /* Инициализация данных */
        async init () {
            this.preloader.table = true

            if (this.preloaderTable) {
                this.setPreloader({
                    active: false,
                    onInit: true
                })
            }

            try {
                if (this.$route.name === ROUTE_NAME.INDEX_CATALOG) {
                    this.requestData(undefined, undefined, undefined, undefined, undefined, 1, 1)
                }
                this.requestData(undefined, undefined, undefined, undefined, undefined, 1)
                await this.requestData()
            } catch ({message}) {
                this.$notify({ type: CONSTANTS.NOTIFY_STATUS.ERROR, title: message })
            } finally {
                this.preloader.table = false
            }
        },

        /* Подгрузка данных для каталога */
        async updateCatalog ({ catalogId = undefined, level, index, moreData = false, offset = 0}) {
            await this.setPreloader({ catalogId, active: true, moreData, offset, mainFlag: this.mainFlag, index })
            await this.requestData(catalogId, level, index, moreData, offset)
            await this.setPreloader({ catalogId, active: false, moreData, offset, mainFlag: this.mainFlag, index  })
        },

        setTableCellsConfig () {
            let tableCellsConfig = localStorage.getItem(this.tableCellsConfigKey)

            if (tableCellsConfig) {
                this.saveTableCellsConfig({newConfig: JSON.parse(tableCellsConfig), key: this.tableCellsConfigKey})
            } else {
                let newConfig = {}
                this.tableCellsConfig.forEach(section => {
                    newConfig[section.id] = section.items.some(metric => metric.visible)
                    section.items.forEach(metric => {
                        newConfig[metric.id] = metric.visible
                    })
                })
                this.saveTableCellsConfig({newConfig, key: this.tableCellsConfigKey})
            }
        },

        setPreloaderWidth () {
            try {
                this.preloaderWidth = this.$refs.container.getBoundingClientRect().width - 10
            } catch (_) {
                this.preloaderWidth = '100%'
            }
        },

        selectItem (id) {
            const idx = this.itemsSelected.indexOf(id)
            if (idx === -1) {
                this.itemsSelected.push(id)
            } else {
                this.itemsSelected.splice(idx, 1)
            }
        },

        toggleAllItemsSelection () {
            if (this.someItemsAreSelected) {
                this.itemsSelected = []
            } else {
                this.itemsSelected = this.catalog.items.map(item => item.df_url_id)
            }
        },

        clearItemsSelected () {
            this.itemsSelected = []
        }
    }
}
