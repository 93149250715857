import { render, staticRenderFns } from "./catalogTable.vue?vue&type=template&id=861dd96a&scoped=true"
import script from "./catalogTable.vue?vue&type=script&lang=js"
export * from "./catalogTable.vue?vue&type=script&lang=js"
import style0 from "./catalogTable.vue?vue&type=style&index=0&id=861dd96a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "861dd96a",
  null
  
)

export default component.exports