<template>
  <vue-table
      small-padding
      head-background
      fixed-bar
      fixed-head
      :offset-top="56"
      class="catalog-table"
      :hoverable-rows="false"
  >
    <template #colgroup>
      <colgroup>
        <col style="min-width: 500px">
      </colgroup>
    </template>

    <template #head>
      <vue-line>
        <vue-item
            :rowspan="dateFilters.comparison ? 3 : 2"
            valign="bottom"
        >
          <div class="catalog-table__segments">
            Сегменты
          </div>
        </vue-item>
        <template v-for="cell of tableCellsConfig">
          <vue-item
              v-if="cell.items.filter(item => item.visible).length"
              :key="cell.id"
              :colspan="getColspan(cell)"
          >
            {{ cell.name }}
          </vue-item>
        </template>
      </vue-line>
      <vue-line>
        <v-table-head-cell-container
            v-for="(metric, i) of metricsVisible"
            :key="`${metric.id}-${i}`"
            :colspan="dateFilters.comparison ? 3 : 1"
            :filter="metricFilters.includes(metric.filterName)"
            :order-by="metric.filterName ? metric.filterName : ''"
            :filter-options="{
                metric: metric,
                diff: dateFilters.comparison
              }"
            :sort="!dateFilters.comparison && metricFilters.includes(metric.filterName)"
            show-buttons-on-hover
        >
          <span class="table-head-metric-name">{{ metric.name }}</span>
        </v-table-head-cell-container>
      </vue-line>

      <vue-line v-if="dateFilters.comparison">
        <template v-for="(metric, i) of metricsVisible">
          <vue-item
              :key="`one-${i}`"
              class="table-segment-metric _nowrap"
          >
            <v-table-head-cell
                :order-by="metric.filterName ? `${metric.filterName}_one` : ''"
                center
                :sort="metricFilters.includes(metric.filterName)"
                sort-column
            >
              {{ dateFiltersFormatted.one.from}} -<br>
              {{ dateFiltersFormatted.one.to }}
            </v-table-head-cell>
          </vue-item>
          <vue-item
              :key="`two-${i}`"
              class="table-segment-metric _nowrap"
          >
            <v-table-head-cell
                :order-by="metric.filterName ? `${metric.filterName}_two` : ''"
                center
                :sort="metricFilters.includes(metric.filterName)"
                sort-column
            >
              {{ dateFiltersFormatted.two.from}} -<br>
              {{ dateFiltersFormatted.two.to }}
            </v-table-head-cell>
          </vue-item>
          <vue-item
              :key="`diff-${i}`"
              class="table-segment-metric"
          >
            <v-table-head-cell
                :order-by="metric.filterName ? `${metric.filterName}_diff` : ''"
                center
                :sort="metricFilters.includes(metric.filterName)"
                sort-column
            >
              Дельта
            </v-table-head-cell>
          </vue-item>
        </template>
      </vue-line>
    </template>
    <template #body>
      <template v-if="data.items && data.items.length">
        <v-table-row
            v-if="$route.name === ROUTE_NAME.INDEX_CATALOG"
            :key="`${JSON.stringify(summaryGAItem)}`"
            :item="summaryGAItem"
            :comparison="dateFilters.comparison"
            :metrics="metricsVisible"
            class="summary"
            summary
        />
        <v-table-row
            :key="`${JSON.stringify(summaryItem)}`"
            :item="summaryItem"
            :comparison="dateFilters.comparison"
            :metrics="metricsVisible"
            class="summary"
            summary
        />
        <template v-for="(item, i) of data.items">
          <vue-line v-if="item.preloader && preloaderTable" class="_active">
            <vue-item :colspan="tableCellsCount">
              <v-preloader
                  class="_relative"
                  static
                  :style="{
                    width: typeof preloaderWidth === 'string' ? preloaderWidth : `${preloaderWidth}px`,
                    left: `${preloaderLeft}px`
                  }"
              />
            </vue-item>
          </vue-line>
          <template v-else-if="item.view">
            <v-table-row
                :key="`${JSON.stringify(item)}` + i"
                :item="item"
                :comparison="dateFilters.comparison"
                :metrics="metricsVisible"
                :checked="itemsSelected.includes(item.df_url_id)"
                @toggle-table-row="$emit('toggle-table-row', { ...$event, index: i })"
                @update-catalog="$emit('update-catalog', { ...$event, index: i })"
                @select="$emit('select-row', item.df_url_id)"
            />
            <vue-line v-if="item.hasNext" :class="{'_active': item.active || item.open && item.active}">
              <vue-item
                  :colspan="tableCellsCount"
                  :style="{ paddingLeft: `${35 + item.level * 30}px` }"
              >
                <v-button
                    type="text-main"
                    name="Показать ещё"
                    @click="loadMore(item)"
                />
              </vue-item>
            </vue-line>
          </template>
        </template>
      </template>
      <vue-line v-else>
        <vue-item :colspan="tableCellsCount">
          <v-no-data
              :style="{
              width: typeof preloaderWidth === 'string' ? preloaderWidth : `${preloaderWidth}px`,
              left: `${preloaderLeft}px`
            }"
          />
        </vue-item>
      </vue-line>
    </template>
  </vue-table>
</template>

<script>
import {mapState} from 'vuex'
import { DATE_FORMAT } from '@/const'
import ROUTE_NAME from '@/router/routeName'

import tableRow from '../tableRow/tableRow'
import noData from '@/services/errors/noData'
import tableHeadCell from '@/components/tableHeadCell/tableHeadCell'
import tableHeadCellContainer from '@/components/tableHeadCell/container/tableHeadCellContainer'

export default {
  components: {
    'v-table-row': tableRow,
    'v-no-data': noData,
    'v-table-head-cell': tableHeadCell,
    'v-table-head-cell-container': tableHeadCellContainer
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    tableCellsConfig: {
      type: Array,
      default: () => ([])
    },

    metricFilters: {
      type: Array,
      default: () => ([])
    },

    preloaderWidth: {
      type: [Number, String],
      default: 0
    },

    allItemsAreSelected: {
      type: Boolean,
      default: false
    },

    someItemsAreSelected: {
      type: Boolean,
      default: false
    },

    itemsSelected: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      preloaderLeft: 0,
      ROUTE_NAME
    }
  },

  computed: {
    ...mapState({
      preloaderTable: state => state.catalog.preloaderTable,
      summary: state => state.catalog.summary,
      summaryGA: state => state.catalog.summaryGA,
      trafficSourceSelectedName: state => state.trafficSourceSelectedName
    }),

    dateFiltersFormatted() {
      const {one, two} = this.dateFilters

      return {
        one: {
          from: this.$moment(one.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE),
          to: this.$moment(one.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE)
        },
        two: {
          from: this.$moment(two.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE),
          to: this.$moment(two.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE)
        }
      }
    },

    /* Список метрик для отображения */
    metricsVisible () {
      let metrics = []

      this.tableCellsConfig.forEach(section => {
        section.items.forEach(metric => {
          if (metric.visible) metrics.push(metric)
        })
      })

      return metrics
    },

    tableCellsCount () {
      let count = this.metricsVisible.length

      if (this.dateFilters.comparison) count *= 3

      return count + 1
    },

    summaryItem () {
      return {
        ...this.summary.data,
        url_title: 'Итого по проекту DF'
      }
    },

    summaryGAItem () {
      return {
        ...this.summaryGA.data,
        url_title: `Итого по всему сайту (${this.trafficSourceSelectedName})`
      }
    },

    metricCellCount () {
      return this.dateFilters.comparison ? this.metricsVisible.length * 3 : this.metricsVisible.length
    }
  },

  mounted () {
    try {
      document.querySelector('.__panel').addEventListener('scroll', this.setPreloaderLeft)
    } catch (_) {
    }
  },

  methods: {
    loadMore(item) {
      let catalogId = item.parentIds[item.parentIds.length - 1]
      let parentIndex = 0

      for (let i = 0; i < this.data.items.length; i++) {
        if (this.data.items[i].catalog_id === catalogId) {
          parentIndex = i
          break
        }
      }

      let data = {
        catalogId: catalogId,
        index: parentIndex,
        moreData: true,
        offset: this.data.items[parentIndex].catalogLength,
        level: item.level
      }

      this.$emit('update-catalog', data)
    },

    getColspan (cell) {
      const count = cell.items.reduce((prev, curr) => {
        if (curr.visible) {
          prev++
        }
        return prev
      }, 0)

      return this.dateFilters.comparison ? count * 3 : count
    },

    setPreloaderLeft () {
      try {
        this.preloaderLeft = document.querySelector('.__panel').scrollLeft
      } catch (_) {
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  &-line._active {
    .table-item {
      background: #F9FCFF !important;
    }
  }

  &-item {
    &._nowrap {
      white-space: nowrap;
    }
  }

  &-segment {
    &-metric {
      color: #455165;
      font-weight: 400;
    }
  }

  &-fixed {
    & /deep/ .table {
      table-layout: fixed !important;
    }
  }

  &-head-metric-name {
    max-width: 140px;
  }
}

.summary {
  font-weight: 500;

  & /deep/ {
    .table-item {
      padding: 12px 8px !important;
    }

    .name-container {
      margin-left: 0;
    }
  }
}

.catalog {
  &__segments {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-left: 32px;
  }

  &-table__segments {
    margin-left: 32px;
  }
}
</style>
