var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"dashboard-container"},[_c('v-page-title',{staticClass:"_border-bottom",attrs:{"primary":_vm.title.primary,"secondary":_vm.title.secondary}})],1),(_vm.viewPage)?[(_vm.preloader.filters)?_c('v-preloader',{attrs:{"clear":"","static":""}}):_c('v-filters',_vm._b({on:{"update-url":_vm.updateUrl}},'v-filters',{
            regions: _vm.regions,
            devices: _vm.devices,
            urlFilters: _vm.urlFilters,
            metricFilters: _vm.metricFilters,
            searchEngines: _vm.searchEngines,
            dateFilters: _vm.dateFilters
          },false)),_c('div',{staticClass:"dashboard-wrapper _border-none _mt-32"},[_c('div',{ref:"container",staticClass:"dashboard-block _mb-32"},[_c('v-container',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"total"},[_vm._v("Всего: "),_c('span',[_vm._v(_vm._s(_vm._f("formatCount")(_vm.catalog.total)))])])]},proxy:true},{key:"head-middle",fn:function(){return [_c('div',{staticClass:"buttons"},[_c('v-filters-reset-button',{attrs:{"metrics":_vm.metricFilters}}),_c('v-button',{staticClass:"settings-button",attrs:{"type":"text-base","name":"Настройки","icon":"settings","offset-icon":"4px","padding-left":"6px","padding-right":"10px","height":"32px"},on:{"click":function($event){return _vm.$modal.open({
                      name: _vm.MODAL_TITLE.SETTINGS_TABLE_CELLS,
                      props: {
                        tableCellsConfig: _vm.tableCellsConfig,
                        saveTableCellsConfigFunc: _vm.saveTableCellsConfig,
                        page: _vm.page
                      }
                    })}}})],1)]},proxy:true},{key:"head-multi-action",fn:function(){return [_c('v-multiaction',{attrs:{"data":_vm.itemsSelected,"action-list":['create']},on:{"close":_vm.clearItemsSelected}})]},proxy:true}],null,false,1082372431)},'v-container',{
                class: '_small-padding',
                load: _vm.preloader.table,
                hasData: _vm.catalog.has_data,
                multiAction: !!_vm.itemsSelected.length
              },false),[_c('v-catalog-table',_vm._b({attrs:{"data":_vm.catalog,"date-filters":_vm.dateFilters,"table-cells-config":_vm.tableCellsConfig,"metric-filters":_vm.metricFilters,"preloader-width":_vm.preloaderWidth},on:{"toggle-table-row":function($event){return _vm.toggleTableRows($event)},"update-catalog":function($event){return _vm.updateCatalog($event)},"select-row":_vm.selectItem,"toggle-all-items-selection":_vm.toggleAllItemsSelection}},'v-catalog-table',{
                  data: _vm.catalog,
                  dateFilters: _vm.dateFilters,
                  tableCellsConfig: _vm.tableCellsConfig,
                  metricFilters: _vm.metricFilters,
                  preloaderWidth: _vm.preloaderWidth,
                  allItemsAreSelected: _vm.allItemsAreSelected,
                  someItemsAreSelected: _vm.someItemsAreSelected,
                  itemsSelected: _vm.itemsSelected
                },false)),_c('v-pagination',{attrs:{"pageNumber":_vm.urlFilters.page,"limit":_vm.urlFilters.limit,"count":_vm.catalog.total},on:{"limit":function($event){return _vm.updateUrl({limit: $event})}}})],1)],1)])]:(_vm.lackOfProjects)?_c('v-no-projects'):_c('v-error',_vm._b({},'v-error',{
        title: _vm.$t('modules.pages')['Error'],
        subtitle: _vm.$t('modules.pages')['No access to projects']
      },false))],2)
}
var staticRenderFns = []

export { render, staticRenderFns }