<template>
  <vue-line
      :class="{
        '_active': active || open && active,
        '_category': item.is_listing
      }"
  >
    <vue-item :style="style">
      <div
          class="query-name"
          :class="{ '_card': !isListing && !summary }"
          :style="{marginLeft: summary ? '32px' : undefined}"
      >
        <v-button
            v-if="isListing"
            :class="{ '_active': open }"
            @click="updateTable(item.hasData)"
            type="text-base"
            padding-left="4px"
            padding-right="4px"
            height="28px"
            width="28px"
            :icon="open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
            :disabled="preloaderTable && !item.hasData"
        />
        <v-checkbox
            v-if="!summary && !isListing"
            :value="checked"
            class="query-name__checkbox"
            @input="$emit('select')"
        />
        <v-table-item-title
            :title="item.url_title"
            :url="item.url_name"
            :level="+item.level"
            :is-listing="isListing"
            :listing-page="item.listingPage"
            :type="item.type_id"
            :entity-count="entityCount"
        />
      </div>
    </vue-item>
    <template v-for="(metric, i) in metrics">
      <template v-if="comparison">
        <v-table-data-cell
            :key="`${i}-one`"
            :value="item[`${metric.id}_one`]"
            :is-listing="item.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            :data-test="`table_cell_${metric.id}_one${isMainPage ? '--main' : ''}${summary ? '_total' : ''}${item.level === '0' ? '_main' : ''}`"
        />
        <v-table-data-cell
            :key="`${i}-two`"
            :value="item[`${metric.id}_two`]"
            :is-listing="item.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            :data-test="`table_cell_${metric.id}_two${isMainPage ? '--main' : ''}${summary ? '_total' : ''}${item.level === '0' ? '_main' : ''}`"
        />
        <v-table-data-cell
            :key="`${i}-diff`"
            :value="item[`${metric.id}_diff`]"
            :is-listing="item.is_listing"
            :additional="metric.additional"
            :round-number-digits="metric.id === 'conversions' ? 2 : 0"
            diff
            :diff-prc="getDiffPrc(metric)"
            :data-test="`table_cell_${metric.id}_diff${isMainPage ? '--main' : ''}${summary ? '_total' : ''}${item.level === '0' ? '_main' : ''}`"
        />
      </template>

      <v-table-data-cell
          v-else
          :key="`${i}-value`"
          :value="item[metric.id]"
          :is-listing="item.is_listing"
          :additional="metric.additional"
          :round-number-digits="metric.id === 'conversions' ? 2 : 0"
          :data-test="`table_cell_${metric.id}${isMainPage ? '--main' : ''}${summary ? '_total' : ''}${item.level === '0' ? '_main' : ''}`"
      />
    </template>
  </vue-line>
</template>

<script>
import { mapState } from 'vuex'
import tableItemTitle from '@/services/components/tableItemTitle'
import tableDataCell from '@/services/components/tableDataCell'

import {roundNumber, hmsToSeconds} from '@/function'

export default {
  components: {
    'v-table-item-title': tableItemTitle,
    'v-table-data-cell': tableDataCell
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    comparison: {
      type: Boolean,
      default: false
    },

    metrics: {
      type: Array,
      default: () => ([])
    },

    summary: {
      type: Boolean,
      default: false
    },

    checked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      preloaderTable: state => state.catalog.preloaderTable,
      entityCountTypes: state => state.entityCountTypes
    }),

    style () {
      return { paddingLeft: `${8 + this.item.level * 30}px` }
    },

    active () {
      try {
        return this.item.active
      } catch (_) {
       return false
      }
    },

    open () {
      try {
        return this.item.open
      } catch (_) {
        return false
      }
    },

    isListing () {
      try {
        return this.item.is_listing
      } catch (_) {
        return false
      }
    },

    isMainPage () {
      try {
        return this.item.url_title.toLowerCase().includes('главная')
      } catch (_) {
        return false
      }
    },

    entityCount () {
      return this.entityCountTypes.map(type => {
        return {
          type: type.id,
          count: this.item[`count_${type.id}`] || 0,
          name: type.name
        }
      })
    }
  },

  methods: {
    updateTable (hasData) {
      if (hasData) {
        this.$emit('toggle-table-row', {
          open: this.open,
          catalogId: this.item.catalog_id
        })
      } else {
        this.$emit('update-catalog', {
          catalogId: this.item.catalog_id,
          level: this.item.level
        })
      }
    },

    roundNumber,

    getDiffPrc (metric) {
      const diff = this.getValue(this.item[`${metric.id}_diff`])
      const one = this.getValue(this.item[`${metric.id}_one`])
      const two = this.getValue(this.item[`${metric.id}_two`])

      return metric.additional || diff === undefined
          ? undefined
          : diff === 0
              ? 0
              : diff === one
                  ? 100
                  : roundNumber(diff * 100 / two, 2)
    },

    getValue (val) {
      return typeof val === 'string' ? hmsToSeconds(val) : val
    }
  }
}
</script>

<style scoped lang="scss">
.table-line._active {
  .table-item {
    background: #F9FCFF !important;
  }

  &._category {
    .table-item {
      background: #F1F7FD !important;
    }
  }

  & /deep/ .name-container__top._category {
    font-weight: 500;
  }
}

.query-name {
  display: flex;
  align-items: center;

  &._card {
    margin-left: 28px;
  }

  .button {
    &._active {
      & /deep/ .button__icon {
        fill: #0E32F5;
      }
    }
  }

  &__checkbox {
    margin: 0 5px;
  }
}
</style>
