import CONSTANTS from '@/const/'

import trafficSourceMixin from '@/mixins/trafficSourceMixin'

import selectTreeMixedSegments from '@/services/components/selectTreeMixedSegments'
import filterTraffic from '@/services/components/filterTraffic'
import filterRegion from '@/services/components/filterRegion'

export default {
  mixins: [trafficSourceMixin],

    components: {
      'v-select-tree-mixed-segments': selectTreeMixedSegments,
      'v-filter-traffic': filterTraffic,
      'v-filter-region': filterRegion
    },

    props: {
      activeProjectId: {
          type: [String, Number]
      },

      regions: {
          type: Array,
          default: () => ([])
      },

      devices: {
          type: Array,
          default: () => ([])
      },

      urlFilters: {
          type: Object,
          default: () => ({})
      },

      metricFilters: {
          type: Array,
          default: () => ([])
      },

      searchEngines: {
          type: Array,
          default: () => ([])
      },

      dateFilters: {
          type: Object,
          default: () => ({})
      }
    },

    data () {
        return {
            filters: {},

            availableDateTo: +this.$moment.utc().startOf('day').subtract(1, 'days').format('x')
        }
    },

    watch: {
        urlFilters () {
            this.setFilters()
        }
    },

    created () {
      this.setFilters()
    },

    methods: {
        /* Изменение URL фильтров */
        updateFilters(filter, value) {
            switch (filter) {
                case 'date':
                    const queryRoute = this.$route.query
                    let query = {
                        period_one_date_from: this.$moment.utc(+value.one.from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT),
                        period_one_date_to: this.$moment.utc(+value.one.to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT),
                        period_two_date_from: undefined,
                        period_two_date_to: undefined
                    }

                    if (value.two.from && value.two.to) {
                        query.period_two_date_from = this.$moment.utc(+value.two.from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
                        query.period_two_date_to = this.$moment.utc(+value.two.to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
                    }

                    for (let metric of this.metricFilters) {
                        query[`${metric}_from`] = undefined
                        query[`${metric}_to`] = undefined
                        query[`${metric}_diff_from`] = undefined
                        query[`${metric}_diff_to`] = undefined

                        if ((queryRoute.period_two_date_from && queryRoute.period_two_date_to && !value.two.from && !value.two.to) || (!queryRoute.period_two_date_from && !queryRoute.period_two_date_to && value.two.from && value.two.to)) {
                            query.order_by = undefined
                            query.order_direction = undefined
                        }
                    }

                    this.updateUrl(query)
                    break

                case 'is_mobile':
                    if (value === 0) {
                        this.updateUrl({[filter]: String(value)})
                    } else {
                        this.updateUrl({[filter]: value})
                    }
                    break

                default:
                    this.updateUrl({[filter]: value})
            }
        },

        /* Обновление URL фильтров */
        updateUrl(query) {
            this.$emit('update-url', query)
        },

        setFilters() {
            this.$set(this, 'filters', {
                ...this.urlFilters
            })
        }
    }
}
